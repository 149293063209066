import React from "react";
import graduationPhoto from '../../assets/stanford_graduation_photo.jpg';
import './home.scss';

function Home() {
  return (
    <div id="home">
      <div className="d-flex flex-column flex-sm-row justify-content-around my-5 mx-sm-10" id="home-div">
        <div className="ml-sm-5" id="home-profile-image">
            <img src={graduationPhoto} alt="Graduation" className="image-responsive" width="85%"/>
        </div>
        <div className="mx-5 mt-4 mt-sm-0">
            Hi there, welcome to my page! =)
            <br />
            <br />
            My name is Chloe. I am currently a Machine Learning Engineer at a start-up in stealth mode 
            (we can't wait to tell you about it soon!) and a full-time dog mom &#128054;.
            <br />
            <br />
            I worked in wet-lab research at the <a href="https://kostaslab.johnshopkins.edu/">Konstantopoulos Lab</a>
            &nbsp;for the first 2 years of my undergrad, investigating cell migration and decision-making in 
            complex microenvironments, a crucial piece of the puzzle in helping us understand cancer metastasis. 
            While I loved some parts of it and was constantly amazed by the intricacies of biological systems, 
            I eventually decided that I wanted to pursue something a little different; I spent the next internships 
            in bioinformatics at the National University of Singapore, Amoy Diagnostics, AbbVie, and Canon Medical 
            Systems. 
            <br />
            <br />
            Coincidentally, despite having very little background in machine learning back then, 
            I got an offer for a Machine Learning Intern position at an AutoML startup the summer after 
            I graduated from college. I learned <i>so much</i> there. The tech world is fascinating and 
            incredibly fast-moving; I continued to work in tech ever since. 
            <br />
            <br />
            All in all, life has been unpredictable for me in rather many ways. I've had crazy and amazing 
            opportunities to do things that I was vastly unqualified for; all of them led me to great things. 
            I'm always looking for new passions and adventures; drop me a message if you want to chat more!
        </div>
      </div>
    </div>
  );
}

export default Home;