import React from "react";
import "./contact.css";

function Contact() {
    return (
        <div id="contact" className="px-5 mx-sm-10 px-sm-5">
            <div className="mt-5 mb-4">
                <h3>Contact</h3>
            </div>
            Feel free to drop me a message on <a href="https://www.linkedin.com/in/chloe-he">Linkedin</a>.
            <br />
            <br />
            I help maintain an ML-focused <a href="https://discord.gg/Mw77HPrgjF">Discord server</a>; join
            us if you want to learn more about MLOps (or anything ML-related)!
        </div>
    );
}

export default Contact;