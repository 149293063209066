import React from "react";
import './projects.css';

function Projects() {
    return (
        <div id="projects" className="px-5 mx-sm-10 px-sm-5 my-5">
            <div className="mt-5 mb-4">
                <h3>Open-Source &amp; Community Projects</h3>
            </div>
            <div className="my-3 my-md-2">
                <code>RecList</code>: Behavioral "black-box" testing for recommender systems
                [<a href="https://github.com/jacopotagliabue/reclist">GitHub</a>]
                [<a href="https://arxiv.org/abs/2111.09963">arXiv</a>]
            </div>
            <div className="my-3 my-md-2">
                <code>MLOps Learning</code>: A community of learners who came together to explore 
                the world of MLOps! (For example, we designed Twitter's trending hashtag feature&nbsp;
                <a href="https://mlops-discord.github.io/blog/designing-twitters-trending-hashtags-solution/">here</a>)
                [<a href="https://mlops-discord.github.io/">website</a>]
                [<a href="https://github.com/mlops-discord">GitHub</a>]
            </div>
            <div className="mt-5 mb-4">
                <h3>Publications</h3>
            </div>
            <div className="my-4 citation-text">
                Chrisman, B., <b>He, C.</b>, Jung, JY. et al. Transmission dynamics of human herpesvirus 6A, 
                6B and 7 from whole genome sequences of families. <i>Virol J</i> <b>19</b>, 225 (2022).&nbsp;
                <a href="https://doi.org/10.1186/s12985-022-01941-9" className="wrap-link">
                https://doi.org/10.1186/s12985-022-01941-9</a>
            </div>
            <div className="my-4 citation-text">
                Chrisman, B., <b>He, C.</b>, Jung, JY. et al. The human “contaminome”: bacterial, viral, 
                and computational contamination in whole genome sequences from 1000 families. <i>Sci Rep</i>
                &nbsp;<b>12</b>, 9863 (2022).&nbsp;
                <a href="https://doi.org/10.1038/s41598-022-13269-z">
                  https://doi.org/10.1038/s41598-022-13269-z
                </a>
            </div>
            <div className="my-4 citation-text">
                 Chia, P. J., Tagliabue, J., Bianchi, F., <b>He, C.</b>, &amp; Ko, B. (2022). Beyond NDCG: 
                 behavioral testing of recommender systems with RecList.&nbsp;<i>WWW '22: Proceedings of The 
                   ACM Web Conference 2022</i>.
            </div>
            <div className="my-4 citation-text">
                Chi, N. A., Washington, P., Kline., A., Husic, A., Hou, C., <b>He, C.</b>, Dunlap, K., &amp; 
                Wall, D. (2022). Classifying Autism from Crowdsourced Semi-Structured Speech Recordings: A 
                Machine Learning Approach.&nbsp;<i>JMIR Pediatrics and Parenting</i>.&nbsp;
                <a href="http://dx.doi.org/10.2196/35406" className="wrap-link">http://dx.doi.org/10.2196/35406</a>
            </div>
            <div className="my-4 citation-text">
                Chrisman, B., Paskov, K., <b>He, C.</b>, Jung, J.-Y., Stockham, N., Washington, P., &amp;
                Wall, D. (2022). A Method for Localizing Non-Reference Sequences to the Human Genome.&nbsp;
                <i>Proceedings of the Pacific Symposium on Biocomputing</i>, 27:313-324(2022).&nbsp;
                <a href="https://psb.stanford.edu/psb-online/proceedings/psb22/chrisman.pdf" className="wrap-link">
                    https://psb.stanford.edu/psb-online/proceedings/psb22/chrisman.pdf</a>
            </div>
            <div className="my-4 citation-text">
                Chrisman, B., <b>He, C.</b>, Washington, P., Paskov, K., Stockham, N., Jung, J.-Y., Wall, D., 
                &amp; Varma, M. (2021). Mapping the Unmapped Readspace in Whole Genome Sequencing Data: 
                The Blood Microbiome of 1,000 Families. <i>AMIA Informatics Summit Proceedings</i>, 698–699. 
            </div>
            <div className="my-4 citation-text">
                Zhao, R., Afthinos, A., Zhu, T., Mistriotis, P., Li, Y., Serra, S. A., Zhang, Y., 
                Yankaskas, C. L., <b>He, S.</b>, Valverde, M. A., Sun, S. X., &amp; Konstantopoulos, K. (2019). 
                Cell Sensing and decision-making in confinement: The role of trpm7 in a tug 
                of war between hydraulic pressure and cross-sectional area. <i>Science Advances</i>, 
                5(7). <a href="https://doi.org/10.1126/sciadv.aaw7243" className="wrap-link">
                    https://doi.org/10.1126/sciadv.aaw7243</a>
            </div>
            <div className="mt-5 mb-4">
                <h3>Talks</h3>
            </div>
            <div className="my-3 my-md-2">
                Nov 3, 2022: <b>Real-time Machine Learning: Challenges and Solutions</b>, Google Pacific Region DevFest
                [<a href="https://youtu.be/BccvzWv_aLg?t=6252"
                className="wrap-link">recording</a>]
                [<a href="https://docs.google.com/presentation/d/1ifLowI3V-nIUetyPQbDO_Q4Hlv1zbRYPd26z0kYNhMM/edit?usp=sharing"
                className="wrap-link">slides</a>]
            </div>
            <div className="my-3 my-md-2">
                June 19, 2022: <b>Building Blocks of RecSys: Crash Course on Recommender Systems</b>, RecSys Series at MLOps Learners Community
                [<a href="https://www.youtube.com/watch?v=BdY6rzYwFe0"
                className="wrap-link">recording</a>]
                [<a href="https://docs.google.com/presentation/d/1rTBqrd7-fXs8vI3oSfwaEOPu_7l4Pxh4XTAbxa9SKCI/edit?usp=sharing"
                className="wrap-link">slides</a>]
            </div>
            <div className="my-3 my-md-2">
                Mar 23, 2022: <b>Beyond NDCG: Behavioral Testing for Recommender Systems</b>, NVIDIA GTC
                [<a href="https://www.nvidia.com/gtc/session-catalog/?tab.scheduledorondemand=1583520458947001NJiE&search=beyond%20ndcg#/session/1637958202880001wnWx"
                className="wrap-link">recording</a>]
            </div>
            <div className="my-3 my-md-2">
                Jan 26, 2022: <b>Evaluation for RecSys</b>, CS 329S (Machine Learning Systems Design) at Stanford
                [<a href="https://docs.google.com/presentation/d/e/2PACX-1vQZvsblf-15mwOs-f-UOxY9KoPcUaGS5Uof7i80Wyq1MQk5jUDkLIjLPWf5fFCf3DwxBsEx6QmsLjN0/pub?start=false&amp;loop=false&amp;delayms=3000"
                className="wrap-link">slides</a>]
            </div>
            <div className="my-3 my-md-2">
                Jan 24, 2022: <b>Distributed Training</b>, CS 329S (Machine Learning Systems Design) at Stanford 
                [<a href="https://docs.google.com/presentation/d/1RqbEbMDmxq53jhjVi9V30-DYMv0PiUqlNlTZsw9Vm9Y/edit?usp=sharing"
                className="wrap-link">slides</a>]
            </div>
            <div className="my-3 my-md-2">
                Mar 22, 2021: <b>	Mapping the Unmapped Readspace in Whole Genome Sequencing Data: The Blood 
                  Microbiome of 1,000 Families</b>, AMIA 2021 Virtual Informatics Summit
            </div>
        </div>
    );
}

export default Projects;