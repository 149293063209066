import React from "react";
import './footer.css';

function Footer() {
  return (
    <div className="footer">
      <footer className="py-4 bg-dark fixed-bottom">
        <div className="container d-flex flex-column align-items-center">
          <div className="d-flex flex-row mb-2">
            <a href="https://www.linkedin.com/in/chloe-he"><i className="bi-linkedin footer-icon mx-2"></i></a>
            <a href="https://www.github.com/chloeh13q"><i className="bi-github footer-icon mx-2"></i></a>
          </div>
          <p className="m-0 text-center text-white">
            Copyright &copy; Chloe He 2022
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;