import React from "react";
import cashewPhoto from '../../assets/cashew_photo.jpg';
import katyPhoto from '../../assets/katy_photo.jpeg';
import './funFacts.css';

function FunFacts() {
    return (
        <div id="fun-facts" className="px-5 mx-sm-10 px-sm-5">
            <div className="mt-5 mb-4">
                <h3>Fun Facts</h3>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <ul id="fun-facts-list">
                        <li>I've traveled to 20 countries and lived in 5 cities across 3 different countries.</li>
                        <li>I had a pet hedgehog in college and I have a golden retriever now (his name is Cashew 
                            and one of his videos has over 1.7 million views on the Internet).</li>
                        <li>I was an extra in a TV show in elementary school!</li>
                        <li>I technically never graduated from middle school...</li>
                        <li>I wrote for <a href="https://spoonuniversity.com/chapter/jhu">Spoon University</a> in 
                        college and had close to 400k views.</li>
                        <li>I collect postcards!</li>
                    </ul>
                </div>
                <div className="col-lg-6" id="fun-facts-photo-div">
                    <figure className="col-sm-6">
                        <img src={cashewPhoto} alt="Cashew" className="image-responsive" width="100%"></img>
                        <figcaption className="mt-2">Cashew</figcaption>
                    </figure>
                    <figure className="col-sm-6">
                        <img src={katyPhoto} alt="Katy" className="image-responsive" width="97%"></img>
                        <figcaption className="mt-2">Katy</figcaption>
                    </figure>
                </div>
            </div>
            <div className="mt-5 mb-3">
                <h3>Bucket List</h3>
            </div>
            <div>(Inspired by <a href="https://huyenchip.com/list-100/">Chip Huyen's List 100</a>!)</div>
            <div className="mt-3">
                <ol id="bucket-list">
                    <li>&nbsp;<i className="bi bi-check-square bucket-list-icon">&nbsp;</i><strike>Visit Iceland</strike></li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Be one of the founding/early members at a startup</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Work at a café in Europe</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Adopt a shelter pet (most likely a dog, but maybe a horse, or perhaps both!)</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Have a design portfolio</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Have a patent approved by USPTO</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Ride a hot air balloon (ideally in Turkey)</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Become a blood donor!</li>
                    <li>&nbsp;<i className="bi bi-x-square bucket-list-icon">&nbsp;</i>Learn to fly a plane</li>
                    <li></li>
                </ol>
            </div>
        </div>
    );
}

export default FunFacts;