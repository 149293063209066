import React from "react";
import "./blog.css";

function Blog() {
    return (
        <div id="blog" className="px-5 mx-sm-10 px-sm-5">
            <div className="mt-5 mb-4">
                <h2>Upcoming...</h2>
            </div>
        </div>
    );
}

export default Blog;